














































































import Vue from "vue";
import _ from "lodash";

const moment = require("moment");

import store from "../../store/shop";
import commonStore from "../../store/common";
import ShopAPI from "../../common/api/api_shop";
import notificationMixin from "../../mixin/notification";
import { Category } from "../../model/entity/category";
import { TranslateServiceBuilder } from "../../services/Translate/translateServiceBuilder";
import { Menu } from "../../model/entity/menu";
import { isMenuPeriodAvailable } from "../../common/util/checkPeriod";
import * as constant from "../../common/util/constant";

const translate = TranslateServiceBuilder.Instance()._;
const api = new ShopAPI();

export default Vue.component("Order", {
  components: {
    QButton: () => import("../../components/elements/QButton.vue"),
    SHOrderCardSkeleton: () =>
      import("../../components/components/SHOrderCardSkeleton.vue"),
    QTranslate: () => import("../../components/elements/QTranslate.vue"),
    QRemainMenuOption: () => import("../../components/elements/QRemainMenuOption.vue"),
  },
  mixins: [notificationMixin],
  data() {
    return {
      categories: null,
      selectedCategory: {},
      modal: {
        menu: { menu_options: undefined },
        order: { quantity: 0, tmp_options: {} },
      },
      isNaizei: "",

      isCheck: false,

      errors: "",
      mode: "",
      isLoading: false,
      langKey: store.state.langKey,
      random: 1,
      interval: null,
    };
  },
  mounted() {
    api.set_token(store.state.token);
    commonStore.dispatch("fetchHoliday");
    this.fetchCategories();
  },
  destroyed() {
    this.interval && clearInterval(this.interval);
  },
  watch: {
    menuChange(newVal, oldVal) {
      console.log('menuChange', newVal);
      if (newVal != '') {
        this.fetchCategories();
      }
    }
  },
  computed: {
    isCategoryNotFound() {
      return _.isEmpty(this.category);
    },
    prefix() {
      return this.$route.params.prefix;
    },
    shoppix() {
      return this.$route.params.shoppix;
    },
    categoryId() {
      return this.$route.params.category_id;
    },
    taxType(): string {
      if (this.isNaizei == true) {
        return " (" + translate("hall-0096", "税込") + ")";
      }
      if (this.isNaizei == false) {
        return " (" + translate("hall-0097", "税抜") + ")";
      }
    },
    isModalMenu(): boolean {
      return this.mode === "menu";
    },
    category(): null | Category {
      if (this.categories) {
          return _.find(this.categories, (cat) => cat.id === this.categoryId);
      } else {
        return null;
      }
    },
    menuNameOfCategoryOption: () => (category) => {
      return _.map(category.menus, (menu) => menu.name).join("・");
    },
    menus() {
      this.random;
      let menus = this.category.menus;
      if (menus) {
        const currentTime = moment();
        return menus.filter((item) => this.isMenuAvaible(item, currentTime));
      }
      return [];
    },
    menuChange() {
      return store.state.pageChange.menu;
    }
  },
  methods: {
    intervalCheckAvaiable() {
      const self = this;
      this.interval = setInterval(function () {
        self.random = Date.now();
      }, constant.TIME_RE_COMPUTED);
      // 30 * 60
    },
    isMenuAvaible(menu: Menu, currentTime): boolean {
      // return item.menu_options.;
      return isMenuPeriodAvailable(menu, currentTime);
    },
    isSoldout(menu): boolean {
        if (menu.is_set_maximum_order_per_day === true) {
          if (menu.remain_order === 0) {
            return true;
          } else {
            return false;
          }
        } else {
          return menu.is_soldout;
        }
    },

    handleClickMenu: function (menu) {
      if (this.isLoading) {
        return;
      }

      this.mode = "menu";
      this.modal.menu = menu;
    },
    handleClickSubmit: function () {
      if (this.isLoading) {
        return;
      }
      this.mode = "";
      let message = "";
      this.isLoading = true;
      api
        .soldout_edit({ menu_id: this.modal.menu.id, is_soldout: this.isSoldout(this.modal.menu) })
        .then((response) => {
          if (response.data.status !== "success") {
            message =
              response.data.message ||
              translate("hall-0046", "保存に失敗しました。");
            this.showErrorNotification(message);
            this.errors = message;
            this.isLoading = false;
            return;
          }
          message = response.data.message;
          this.showSuccessNotification(message);
          this.fetchCategories();
        })
        .catch((err) => {
          message =
            err.response.data.message ||
            translate("hall-0046", "保存に失敗しました。");
          this.showErrorNotification(message);
          this.errors = message;
          this.isLoading = false;
        });
    },

    handleClickCloseEditor: function () {
      this.closeEditor();
    },
    closeEditor: function () {
      this.mode = "";
      this.isLoading = false;
      this.isCheck = false;
    },
    fetchCategories() {
      this.isLoading = true;
      let message = "";
      api
        .soldout_index()
        .then((response) => {
          if (response.data.status !== "success") {
            message =
              response.data.message ||
              translate("hall-0048", "データの取得に失敗しました。");
            this.showErrorNotification(message);
            this.errors = message;
            return;
          }
          this.isLoading = false;
          this.isCheck = false;
          this.categories = response.data.data.categories;
          this.isNaizei = response.data.data.is_naizei;
          store.commit("setSelectedCategory", this.category);
          this.intervalCheckAvaiable();
        })
        .catch((err) => {
          this.isLoading = false;
          message =
            err.response.data.message ||
            translate("hall-0048", "データの取得に失敗しました。");
          this.showErrorNotification(message);
          this.errors = message;
        });
    },
    isValid() {
      return true;
      const optionIds = this.modal.order.tmp_options || [];
      const summary = _.countBy(this.modal.menu.menu_options, (menu_option) => {
        const allOption = _.map(
          menu_option.menus_menu_options,
          (opt) => opt.id
        );
        const selectCount =
          _.countBy(optionIds, (value) => {
            return _.includes(allOption, value.menus_menu_option_id);
          }).true || 0;
        const min = menu_option.min;
        const max = menu_option.max;

        return min <= selectCount && selectCount <= max;
      });

      const falseCount = summary.false || 0;

      return falseCount == 0;
    },
  },
  filters: {
    taxPrice: function (prices, flg) {
      if (flg == true) {
        return prices["include"];
      }
      if (flg == false) {
        return prices["exclude"];
      }
      return;
    },
    numberFormat: function (number) {
      if (typeof number !== "number") {
        return number;
      }
      return number.toLocaleString();
    },
    isStatus(menu) {
      if (menu.is_soldout === true) {
        return "on";
      }
      if (menu.is_set_maximum_order_per_day === true && menu.remain_order === 0) {
        return "on";
      }
      return '';
    },
  },
});
